export const Trezor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="30" viewBox="0 0 21 30" fill="none">
      <path
        d="M10.3405 0C6.22508 0 2.89452 3.33056 2.89452 7.44601V10.2367C1.44934 10.4983 0 10.8472 0 11.2998V25.8638C0 25.8638 0 26.2666 0.452658 26.4576C2.09302 27.1221 8.54651 29.4061 10.0291 29.9294C10.2201 30 10.2741 30 10.3239 30C10.3945 30 10.4277 30 10.6188 29.9294C12.1013 29.4061 18.5714 27.1221 20.2118 26.4576C20.6312 26.2832 20.6478 25.8804 20.6478 25.8804V11.2998C20.6478 10.8472 19.2193 10.4817 17.7699 10.2367V7.44601C17.7907 3.33056 14.4394 0 10.3405 0ZM10.3405 3.55897C12.7658 3.55897 14.2317 5.02492 14.2317 7.45017V9.87541C11.5116 9.68438 9.1902 9.68438 6.45349 9.87541V7.45017C6.45349 5.02076 7.91944 3.55897 10.3405 3.55897ZM10.3239 13.4468C13.7085 13.4468 16.549 13.7085 16.549 14.1777V23.2641C16.549 23.4053 16.5324 23.4219 16.4078 23.4718C16.2874 23.5257 10.6354 25.5648 10.6354 25.5648C10.6354 25.5648 10.407 25.6354 10.3405 25.6354C10.2699 25.6354 10.0457 25.5482 10.0457 25.5482C10.0457 25.5482 4.39369 23.5091 4.27326 23.4551C4.15282 23.4012 4.13206 23.3846 4.13206 23.2475V14.1611C4.09884 13.6919 6.93937 13.4468 10.3239 13.4468Z"
        fill="black"
      />
    </svg>
  );
};
