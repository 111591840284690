import { call, put } from "redux-saga/effects";
import { apiUrl, fetchApi } from "helpers/common";
import { setCommonError } from "Redux/user/Actions/userActions";
import { defaultError } from "helpers/errorHandler";
import { setOverAllCapacity } from "../Actions/accountActions";

export function* getOverAllCapacityProcess() {
  try {
    const capacity = yield call(fetchApi, "get", "200", {
      url: `/over-all-capacity`,
      mainUrl: apiUrl,
      query: { format: "json" },
    });

    yield put(setOverAllCapacity(capacity));
  } catch (err) {
    console.error("catch getOverAllCapacityProcess", err);
    if (err?.code || err?.message) {
      yield put(
        setCommonError({
          message: err?.message ? `Get over-all-capacity data: ${err?.message}` : err?.code,
          isBottomText: false,
        })
      );
    } else {
      yield put(setCommonError({ message: defaultError, isBottomText: true }));
    }
  }
}
