import * as React from "react";

function SvgUser() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.4548 40.8942C38.5432 38.3426 36.5345 36.0879 33.7402 34.4798C30.9459 32.8717 27.5222 32 24 32C20.4779 32 17.0541 32.8716 14.2598 34.4798C11.4655 36.0879 9.45679 38.3426 8.54519 40.8942"
        stroke="#D9E1FF"
        strokeLinecap="round"
      />
      <circle cx="24" cy="16" r="8" stroke="#D9E1FF" strokeLinecap="round" />
    </svg>
  );
}

export default SvgUser;
