import React from "react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { makeStore } from "store";
import ext from "./helpers/ext";
import { observerEtherAddress } from "helpers/EtherAddObserver";
import { createRoot } from "react-dom/client";

ext();
observerEtherAddress.init();

const store = makeStore({});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const container = document.getElementById("root");
// @ts-ignore
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
