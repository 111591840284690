import * as React from "react";

function SvgNewLogotype() {
  return (
    <svg width="174" height="36" viewBox="0 0 174 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_1139)">
        <path
          d="M66.8321 13.2637C64.4922 13.2637 62.5525 14.0032 61.0439 15.5439C59.5353 17.0231 58.7656 18.9336 58.7656 21.2446C58.7656 23.5248 59.5046 25.4046 61.0439 26.9453C62.5525 28.4243 64.4922 29.1948 66.8321 29.1948C69.1719 29.1948 71.1116 28.4552 72.6202 26.9453C74.1288 25.4354 74.9292 23.5248 74.9292 21.2446C74.9292 18.9336 74.1595 17.0538 72.6202 15.5439C71.0808 14.0032 69.1719 13.2637 66.8321 13.2637ZM69.9724 24.7882C69.2027 25.7127 68.1559 26.1441 66.8321 26.1441C65.5082 26.1441 64.4614 25.7127 63.6917 24.7882C62.922 23.8638 62.5218 22.6929 62.5218 21.2446C62.5218 19.7964 62.922 18.5946 63.6917 17.701C64.4614 16.7766 65.5082 16.3451 66.8321 16.3451C68.1559 16.3451 69.2027 16.7766 69.9724 17.701C70.7729 18.6254 71.204 19.7964 71.204 21.2446C71.204 22.6929 70.8037 23.8638 69.9724 24.7882Z"
          fill="#0053F1"
        />
        <path
          d="M81.0857 4.66623C80.747 4.29646 80.4698 4.11157 80.2543 4.11157H75.9441C75.6978 4.11157 75.4515 4.26564 75.0512 4.66623C74.7125 5.00519 74.5586 5.28252 74.5586 5.49822C74.5586 5.71392 74.7125 5.99125 75.0512 6.39183C75.4206 6.7308 75.6978 6.88487 75.9441 6.88487H78.13V28.8555H81.609V5.49822C81.6398 5.25171 81.4551 5.00519 81.0857 4.66623Z"
          fill="#0053F1"
        />
        <path
          d="M99.5291 25.0038V5.49822C99.5291 5.25171 99.3443 5.00519 98.9749 4.66623C98.6362 4.29646 98.3591 4.11157 98.1436 4.11157H94.8185C94.5722 4.11157 94.3259 4.26564 93.9257 4.66623C93.587 5.00519 93.4331 5.28252 93.4331 5.49822C93.4331 5.71392 93.587 5.99125 93.9257 6.39184C94.2951 6.7308 94.5722 6.88487 94.8185 6.88487H96.05V13.7565C95.0341 13.4175 94.0488 13.2634 93.0636 13.2634C90.6621 13.2634 88.6917 14.003 87.1523 15.5437C85.5821 17.0228 84.8124 18.9641 84.8124 21.3368C84.8124 23.5863 85.4898 25.4351 86.8752 26.9142C88.2607 28.3933 90.0156 29.1637 92.1092 29.1637C93.7101 29.1637 95.0956 28.6706 96.2655 27.6846C96.6658 28.2393 97.4047 28.8555 97.7741 28.8555H99.8677V28.4549L99.7754 28.1776C99.6522 27.8694 99.5291 26.6061 99.5291 25.0038ZM96.0192 25.1886C95.188 25.8357 94.172 26.1438 92.9713 26.1438C90.2311 26.1438 88.507 24.141 88.507 21.2752C88.507 18.2862 90.2619 16.2833 93.2176 16.2833C94.2643 16.2833 95.2187 16.4989 96.0192 16.9304V25.1886Z"
          fill="#0053F1"
        />
        <path
          d="M109.073 19.7652C108.15 19.3955 107.595 19.1181 107.349 18.9949C106.549 18.5943 106.117 18.1012 106.117 17.6082C106.117 16.6838 106.979 16.1291 108.427 16.1291C109.689 16.1291 110.674 16.4681 111.382 17.1768C111.629 17.1768 112.029 16.8994 112.521 16.4064C113.013 15.9134 113.291 15.482 113.291 15.2663C112.029 13.9413 110.366 13.2942 108.334 13.2942C104.701 13.2942 102.854 14.9582 102.854 17.7931C102.854 20.0733 104.024 21.1827 107.288 22.5385L109.073 23.3088C110.058 23.7403 110.52 24.2641 110.52 24.7572C110.52 25.7432 109.473 26.3594 107.933 26.3594C106.517 26.3594 105.317 25.8972 104.424 24.9728C104.178 24.9728 103.778 25.2501 103.254 25.774C102.731 26.3287 102.484 26.7293 102.484 26.9449C103.931 28.3933 105.81 29.1327 108.057 29.1327C111.721 29.1327 113.814 27.4071 113.814 24.6031C113.814 23.4629 113.445 22.5385 112.736 21.8914C112.059 21.2443 110.797 20.5048 109.073 19.7652Z"
          fill="#0053F1"
        />
        <path
          d="M126.13 26.0518C125.699 25.5896 125.33 25.3431 125.083 25.3431C124.498 25.9285 123.759 26.175 122.774 26.175C121.666 26.175 121.081 25.5588 121.081 24.3878V16.3761H125.976V13.6028H121.081V8.02539H120.095L117.787 9.28878V13.6028H116.124C115.877 13.6028 115.57 13.7569 115.231 14.0958C114.892 14.4348 114.738 14.7429 114.738 14.9894C114.738 15.236 114.892 15.4825 115.231 15.8831C115.6 16.222 115.877 16.3761 116.124 16.3761H117.571V25.0041C117.571 27.7774 119.049 29.164 122.004 29.164C123.821 29.164 125.422 28.4861 126.807 27.0995C126.838 26.853 126.592 26.4832 126.13 26.0518Z"
          fill="#0053F1"
        />
        <path
          d="M140.693 25.004V18.5946C140.693 15.1126 138.63 13.2637 134.596 13.2637C132.164 13.2637 130.163 14.0032 128.624 15.5439C128.624 15.8521 128.901 16.2527 129.424 16.8073C129.978 17.3004 130.378 17.5777 130.655 17.5777C131.641 16.6225 132.842 16.1603 134.289 16.1603C136.075 16.1603 137.214 17.0538 137.214 18.6871V19.858C136.444 19.7655 135.735 19.7347 135.058 19.7347C130.625 19.7347 128.254 21.522 128.254 24.6959C128.254 27.5615 130.317 29.1639 133.303 29.1639C134.936 29.1639 136.321 28.6709 137.429 27.654C137.86 28.2395 138.599 28.8866 138.999 28.8866H141.093V28.486L141 28.2087C140.816 27.8697 140.693 26.6063 140.693 25.004ZM137.214 25.1272C136.413 25.9284 135.397 26.3598 134.166 26.3598C132.534 26.3598 131.734 25.7127 131.734 24.4185C131.734 22.9702 132.842 22.2615 135.089 22.2615C135.797 22.2615 136.505 22.3232 137.214 22.4155V25.1272Z"
          fill="#0053F1"
        />
        <path
          d="M156.887 25.7743C156.363 25.2813 155.963 25.004 155.717 25.004C154.855 25.7435 153.685 26.1133 152.084 26.1133C149.067 26.1133 147.342 24.1104 147.342 21.2446C147.342 18.2865 149.098 16.2835 151.961 16.2835C153.1 16.2835 153.962 16.5609 154.547 17.0538V18.6562C154.547 18.9027 154.701 19.1493 155.101 19.5499C155.471 19.8888 155.748 20.0428 155.963 20.0428C156.21 20.0428 156.456 19.8888 156.795 19.5499C157.164 19.18 157.349 18.9027 157.349 18.6562V15.698C157.349 15.205 156.795 14.6504 155.748 14.0957C154.701 13.5102 153.439 13.2637 151.961 13.2637C149.56 13.2637 147.559 14.0032 146.05 15.5132C144.51 16.9922 143.74 18.9027 143.74 21.2138C143.74 23.5557 144.51 25.4354 146.05 26.9453C147.559 28.4243 149.56 29.1948 152.022 29.1948C154.393 29.1948 156.333 28.4552 157.78 27.0069C157.687 26.6987 157.41 26.2674 156.887 25.7743Z"
          fill="#0053F1"
        />
        <path
          d="M56.4278 25.7743C55.9043 25.2813 55.504 25.004 55.2583 25.004C54.3962 25.7435 53.2258 26.1133 51.6248 26.1133C48.6078 26.1133 46.8835 24.1104 46.8835 21.2446C46.8835 18.2865 48.639 16.2835 51.5015 16.2835C52.6415 16.2835 53.5028 16.5609 54.088 17.0538V18.6562C54.088 18.9027 54.2416 19.1493 54.6419 19.5499C55.0117 19.8888 55.2887 20.0428 55.504 20.0428C55.7506 20.0428 55.9972 19.8888 56.3358 19.5499C56.7048 19.18 56.8897 18.9027 56.8897 18.6562V15.698C56.8897 15.205 56.3358 14.6504 55.2887 14.0957C54.2416 13.5102 52.9801 13.2637 51.5015 13.2637C49.1009 13.2637 47.0997 14.0032 45.5907 15.5132C44.0514 16.9922 43.2812 18.9027 43.2812 21.2138C43.2812 23.5557 44.0514 25.4354 45.5907 26.9453C47.0997 28.4243 49.1009 29.1948 51.5632 29.1948C53.9343 29.1948 55.8739 28.4552 57.3212 27.0069C57.2283 26.6987 56.9513 26.2674 56.4278 25.7743Z"
          fill="#0053F1"
        />
        <path
          d="M168.432 20.9978L173.297 15.3589C173.297 15.0815 172.958 14.6809 172.281 14.0955C171.634 13.51 171.172 13.2634 170.895 13.2634L165.538 19.4263L164.183 19.6112V5.49822C164.183 5.25171 164.029 5.00519 163.629 4.66623C163.29 4.29646 163.013 4.11157 162.767 4.11157H159.441C159.196 4.11157 158.949 4.26564 158.549 4.66623C158.21 5.00519 158.057 5.28252 158.057 5.49822C158.057 5.71392 158.21 5.99125 158.549 6.39183C158.919 6.7308 159.196 6.88487 159.441 6.88487H160.674V28.8555H164.152V22.6618L165.415 22.5077L171.234 29.1945C171.511 29.1945 171.972 28.8863 172.619 28.3009C173.265 27.6846 173.604 27.2532 173.604 26.9759L168.432 20.9978Z"
          fill="#0053F1"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9845 36C27.9171 36 35.969 27.9411 35.969 18C35.969 8.05887 27.9171 0 17.9845 0C8.05193 0 0 8.05887 0 18C0 20.8488 0.661209 23.5429 1.8384 25.9372L15.1757 17.9843L9.61483 14.8087C8.86123 14.3686 8.57874 13.3939 9.018 12.6078C9.45806 11.8533 10.4321 11.5704 11.2175 12.0105L16.8404 15.2174V8.80347C16.8404 7.92318 17.563 7.2 18.4423 7.2C19.3216 7.2 20.0442 7.92318 20.0442 8.80347V15.2174L25.6679 12.0105C26.4533 11.5704 27.4266 11.8533 27.8666 12.6078C28.3067 13.3625 28.0234 14.3686 27.2698 14.8087L21.6779 17.9843L27.238 21.1599C27.9924 21.5999 28.2749 22.5747 27.8356 23.3608C27.5523 23.8638 26.9873 24.1782 26.4533 24.1782C26.3562 24.1782 26.2623 24.1707 26.1716 24.1546C25.9974 24.1238 25.8326 24.0613 25.6679 23.958L20.0442 20.7511V27.1965C20.0442 28.0769 19.3216 28.8 18.4423 28.8C17.563 28.8 16.8404 28.0769 16.8404 27.1965V20.7196L3.50031 28.6719C6.77505 33.1167 12.0433 36 17.9845 36Z"
        fill="#0053F1"
      />
      <defs>
        <clipPath id="clip0_2_1139">
          <rect width="130.612" height="25.2" fill="white" transform="translate(43.1641 4.05005)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNewLogotype;
