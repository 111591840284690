import * as React from "react";

function SvgUploadFile() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 37.3333H19.8918C20.5875 37.3333 20.9354 37.3333 21.1973 37.522C21.4592 37.7108 21.5692 38.0408 21.7892 38.7008L23.5442 43.9657C23.7642 44.6257 23.8742 44.9557 24.136 45.1445C24.3979 45.3333 24.7458 45.3333 25.4415 45.3333H38.5585C39.2542 45.3333 39.6021 45.3333 39.864 45.1445C40.1258 44.9557 40.2358 44.6257 40.4558 43.9657L42.2108 38.7008C42.4308 38.0408 42.5408 37.7108 42.8027 37.522C43.0646 37.3333 43.4125 37.3333 44.1082 37.3333H52C53.8856 37.3333 54.8284 37.3333 55.4142 36.7475C56 36.1617 56 35.2189 56 33.3333V51.9999C56 53.8855 56 54.8283 55.4142 55.4141C54.8284 55.9999 53.8856 55.9999 52 55.9999H12C10.1144 55.9999 9.17157 55.9999 8.58579 55.4141C8 54.8283 8 53.8855 8 51.9999V33.3333C8 35.2189 8 36.1617 8.58579 36.7475C9.17157 37.3333 10.1144 37.3333 12 37.3333Z"
        fill="#667CBB"
        fillOpacity="0.3"
      />
      <path
        d="M8 29.3333V33.3333C8 35.2189 8 36.1617 8.58579 36.7475C9.17157 37.3333 10.1144 37.3333 12 37.3333H19.8918C20.5875 37.3333 20.9354 37.3333 21.1973 37.522C21.4592 37.7108 21.5692 38.0408 21.7892 38.7008L23.5442 43.9657C23.7642 44.6257 23.8742 44.9557 24.136 45.1445C24.3979 45.3332 24.7458 45.3332 25.4415 45.3332H38.5585C39.2542 45.3332 39.6021 45.3332 39.864 45.1445C40.1258 44.9557 40.2358 44.6257 40.4558 43.9657L42.2108 38.7008C42.4308 38.0408 42.5408 37.7108 42.8027 37.522C43.0646 37.3333 43.4125 37.3333 44.1082 37.3333H52C53.8856 37.3333 54.8284 37.3333 55.4142 36.7475C56 36.1617 56 35.2189 56 33.3333V29.3333"
        stroke="white"
        strokeWidth="1.2"
      />
      <path
        d="M21.3333 24L31.9999 32M31.9999 32L42.6666 24M31.9999 32L31.9999 5.33334"
        stroke="#B4B9C6"
        strokeWidth="1.2"
      />
      <path
        d="M42.6667 13.3333H52C53.8856 13.3333 54.8284 13.3333 55.4142 13.919C56 14.5048 56 15.4476 56 17.3333V51.9999C56 53.8855 56 54.8283 55.4142 55.4141C54.8284 55.9999 53.8856 55.9999 52 55.9999H12C10.1144 55.9999 9.17157 55.9999 8.58579 55.4141C8 54.8283 8 53.8855 8 51.9999V17.3333C8 15.4476 8 14.5048 8.58579 13.919C9.17157 13.3333 10.1144 13.3333 12 13.3333H21.3333"
        stroke="#B4B9C6"
        strokeWidth="1.2"
      />
    </svg>
  );
}

export default SvgUploadFile;
