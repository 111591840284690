export const GameFi = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 36.6667H33.3333" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.6666 30V43.3333" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M50 40H50.0333" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M60 33.3333H60.0333" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M57.7333 16.6667H22.2666C18.9676 16.6674 15.7859 17.8913 13.3367 20.1015C10.8876 22.3118 9.34478 25.3516 9.00663 28.6333C8.98663 28.8067 8.97329 28.97 8.94996 29.14C8.67996 31.3867 6.66663 48.1867 6.66663 53.3333C6.66663 55.9855 7.72019 58.529 9.59556 60.4044C11.4709 62.2798 14.0145 63.3333 16.6666 63.3333C20 63.3333 21.6666 61.6667 23.3333 60L28.0466 55.2867C29.2966 54.0363 30.992 53.3337 32.76 53.3333H47.24C49.0079 53.3337 50.7033 54.0363 51.9533 55.2867L56.6666 60C58.3333 61.6667 60 63.3333 63.3333 63.3333C65.9855 63.3333 68.529 62.2798 70.4044 60.4044C72.2797 58.529 73.3333 55.9855 73.3333 53.3333C73.3333 48.1833 71.32 31.3867 71.05 29.14C71.0266 28.9733 71.0133 28.8067 70.9933 28.6367C70.6559 25.3544 69.1135 22.3138 66.6642 20.1028C64.215 17.8918 61.0329 16.6675 57.7333 16.6667Z"
        stroke="#0053F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GameFi;
