import * as React from "react";

export const SvgWalletBullet: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1214 14.537H6.14627C4.16615 14.537 2.56095 16.1422 2.56095 18.1223V48.8537C2.56095 50.8338 4.16615 52.439 6.14627 52.439H47.1214C49.1015 52.439 50.7067 50.8338 50.7067 48.8537V18.1223C50.7067 16.1422 49.1015 14.537 47.1214 14.537ZM6.14627 11.9761C2.75178 11.9761 0 14.7279 0 18.1223V48.8537C0 52.2482 2.75178 55 6.14627 55H47.1214C50.5159 55 53.2677 52.2482 53.2677 48.8537V18.1223C53.2677 14.7279 50.5159 11.9761 47.1214 11.9761H6.14627Z"
        fill="#0053F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.7067 26.8297H31.7557C30.9071 26.8297 30.2191 27.5177 30.2191 28.3663V36.5613C30.2191 37.41 30.9071 38.0979 31.7557 38.0979H50.7067V26.8297ZM31.7557 24.2688C29.4927 24.2688 27.6582 26.1033 27.6582 28.3663V36.5613C27.6582 38.8243 29.4927 40.6589 31.7557 40.6589H52.2433C52.809 40.6589 53.2677 40.2002 53.2677 39.6345V24.2688H31.7557Z"
        fill="#0053F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3655 32.4638C36.3655 32.1809 36.1362 31.9516 35.8533 31.9516C35.5704 31.9516 35.3411 32.1809 35.3411 32.4638C35.3411 32.7467 35.5704 32.976 35.8533 32.976C36.1362 32.976 36.3655 32.7467 36.3655 32.4638ZM35.8533 30.415C34.7218 30.415 33.8046 31.3323 33.8046 32.4638C33.8046 33.5953 34.7218 34.5126 35.8533 34.5126C36.9848 34.5126 37.9021 33.5953 37.9021 32.4638C37.9021 31.3323 36.9848 30.415 35.8533 30.415Z"
        fill="#0053F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21941 41.6831C8.08791 41.6831 7.17065 42.6004 7.17065 43.7319C7.17065 44.8634 8.08791 45.7806 9.21941 45.7806H15.3657C16.4972 45.7806 17.4144 44.8634 17.4144 43.7319C17.4144 42.6004 16.4972 41.6831 15.3657 41.6831H9.21941Z"
        fill="#0053F1"
      />
      <path
        d="M27.2795 10L22.9169 2.95515C22.531 2.30294 21.6765 2.07947 21.0081 2.45603L9.32736 10H5L19.8437 0.487787C21.6259 -0.516367 23.9048 0.0795451 24.9337 1.81879L30 10H27.2795Z"
        fill="#0053F1"
      />
    </svg>
  );
};

export default SvgWalletBullet;
