import * as React from "react";

function OpenedGiftBox() {
  return (
    <svg width="160" height="149" viewBox="0 0 160 149" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M93.3088 83.2589V147.717H36.1147C33.3042 147.717 31.1963 145.456 31.1963 142.77V83.2589H93.3088Z"
        fill="#E9EDF5"
      />
      <path
        d="M120.29 83.2589V142.77C120.29 145.597 118.042 147.717 115.372 147.717H93.1689V83.2589H120.29Z"
        fill="#DAE1ED"
      />
      <path
        opacity="0.2"
        d="M120.29 83.2589V142.77C120.29 145.597 118.042 147.717 115.372 147.717H93.1689V83.2589H120.29Z"
        fill="url(#paint0_linear_2933_6930)"
      />
      <path
        d="M53.9618 101.353C50.7297 100.646 45.9518 105.735 43.8439 107.714C42.2981 109.127 40.6118 110.682 39.066 112.237C37.6608 113.651 36.396 115.489 34.4287 116.337C32.0397 117.327 29.5102 116.478 27.1213 115.63C26.5592 115.489 26.1376 115.206 25.716 115.065C23.7487 114.358 21.9218 113.51 20.095 112.379C18.2682 111.248 15.1766 109.127 15.3171 106.583C15.4577 104.038 19.6734 101.777 19.6734 101.777L29.7913 105.452C29.5103 105.452 28.6671 106.018 28.386 106.018C27.8239 106.3 27.2618 106.583 26.8403 107.148C26.5592 107.714 26.4187 108.421 26.5592 108.986C26.6997 109.269 26.8403 109.552 26.9808 109.693C27.1213 109.693 27.2618 109.693 27.4024 109.693C28.5266 109.552 30.4939 107.572 31.056 107.007C33.5855 104.887 37.0986 101.918 40.7523 100.08C46.7949 97.1117 48.6218 99.5148 53.9618 101.353Z"
        fill="url(#paint1_linear_2933_6930)"
      />
      <path
        opacity="0.5"
        d="M58.4587 133.299L57.3345 99.6561L68.436 97.8185L69.1387 134.147L67.8739 133.582L67.0308 126.655"
        fill="url(#paint2_linear_2933_6930)"
      />
      <path
        d="M68.9983 147.717H57.8967C57.8967 140.084 57.8967 132.309 57.8967 124.676C57.8967 123.969 57.8967 123.404 57.8967 122.697C57.8967 119.87 57.7562 116.902 57.1941 114.074C56.4915 109.975 56.0699 104.745 54.1025 101.211C54.5241 101.352 54.8052 101.494 55.2267 101.635C59.302 102.766 64.7825 104.745 66.8904 108.703C67.8741 110.541 68.0146 112.944 68.1551 115.064C68.2957 116.76 68.4362 118.315 68.5767 120.011C68.5767 120.153 68.5767 120.436 68.5767 120.577C68.8578 124.818 68.9983 129.2 69.1388 133.44C69.1388 134.712 69.1388 147.717 68.9983 147.717Z"
        fill="url(#paint3_linear_2933_6930)"
      />
      <path
        opacity="0.4"
        d="M101.741 122.273V102.342L110.875 101.776L110.032 130.896L106.8 120.859L101.741 122.273Z"
        fill="url(#paint4_linear_2933_6930)"
      />
      <path
        opacity="0.2"
        d="M110.875 130.896L110.313 147.718H101.741V131.461L110.875 130.896Z"
        fill="url(#paint5_linear_2933_6930)"
      />
      <path
        d="M158.935 108.562C158.935 108.562 161.746 115.63 153.314 117.75C148.958 118.881 141.65 122.132 137.294 120.718C131.954 119.022 129.425 113.792 126.474 108.703C124.787 105.876 122.96 103.19 119.869 102.625C118.885 102.483 118.042 102.625 117.339 102.908C114.81 104.038 113.264 107.29 112.28 109.834C111.016 112.802 110.313 116.054 110.313 119.305C110.454 124.535 111.578 129.624 111.718 134.854C111.718 137.681 111.718 140.508 111.437 143.194C111.297 144.608 111.297 146.587 110.454 147.859C110.454 147.859 110.454 147.859 110.313 148H101.6C102.865 143.759 104.692 137.398 102.303 126.797C100.757 119.87 101.038 109.834 106.097 104.18C108.767 101.07 113.264 99.5149 117.339 99.0909C118.745 98.9495 120.15 98.9495 121.274 99.0909C128.3 99.7977 132.376 104.038 135.186 108.703C137.997 113.227 141.931 121.849 145.304 120.436C148.677 119.022 150.082 110.117 150.082 110.117L158.935 108.562Z"
        fill="url(#paint6_linear_2933_6930)"
      />
      <path
        d="M26.9808 107.148C26.6997 107.714 26.5592 108.42 26.6997 108.986C26.8403 109.269 26.9808 109.551 27.1213 109.693C27.2618 109.693 27.4024 109.693 27.5429 109.693L27.1213 115.63C26.5592 115.489 26.1376 115.206 25.716 115.064C23.7487 114.358 21.9218 113.509 20.095 112.379C18.2682 111.248 15.1766 109.127 15.3171 106.583C15.4577 104.038 19.6734 101.776 19.6734 101.776L29.7913 105.452C29.5103 105.452 28.6671 106.017 28.386 106.017C27.8239 106.159 27.2618 106.441 26.9808 107.148Z"
        fill="url(#paint7_linear_2933_6930)"
      />
      <path
        d="M68.5766 120.153L57.7561 122.839C57.7561 120.012 57.6156 117.044 57.0535 114.216C56.3509 110.117 55.9293 104.886 53.9619 101.352C54.3835 101.494 54.6645 101.635 55.0861 101.776C59.1614 102.907 64.6419 104.886 66.7498 108.845C67.7335 110.682 67.874 113.085 68.0145 115.206C68.2956 116.902 68.4361 118.457 68.5766 120.153Z"
        fill="url(#paint8_linear_2933_6930)"
      />
      <path
        d="M58.7398 57.6733L13.7715 96.9704L0 87.6409L25.1541 55.4116L37.3799 32.2292L56.913 0L69.1388 7.63324L58.7398 57.6733Z"
        fill="#CED7E8"
      />
      <path d="M56.913 0L45.1088 49.0506L0 87.6409L21.7815 37.8835L56.913 0Z" fill="#E9EDF5" />
      <path
        d="M58.7399 57.6733L45.1089 49.0506L49.8868 28.978L52.4162 18.3763L56.9131 0L69.1388 7.63324L65.0636 26.7163L63.0962 36.7526L58.7399 57.6733Z"
        fill="#DAE1ED"
      />
      <path
        d="M63.0958 36.7542L50.3079 28.6965L49.8863 28.9793L33.1637 39.016L28.5264 36.1887L52.1347 18.0944L52.4158 18.3771L65.0631 26.7175L63.0958 36.7542Z"
        fill="url(#paint9_linear_2933_6930)"
      />
      <path
        d="M30.9158 27.8477L34.2884 20.7799L38.2231 13.1467C38.6447 12.2985 39.7689 11.8745 40.612 12.1572L47.0762 14.4189C48.2004 14.8429 48.7626 15.9738 48.2004 17.1046L47.0762 19.9318C46.9357 20.2145 46.6547 20.6385 46.3736 20.7799L33.3047 30.1094C33.0236 30.2508 32.7426 30.5335 32.6021 30.9575L28.1052 41.4179C27.9647 41.5593 27.9647 41.7006 27.8242 41.842C26.981 42.8315 22.9058 47.7789 20.7979 52.4437C18.2684 57.9566 15.4579 62.3386 13.9121 63.4695C13.6311 63.7522 13.35 63.8935 13.069 64.0349C12.3663 64.459 11.3826 64.459 10.8205 63.8935L5.05898 59.0874C4.35635 58.522 4.0753 57.3912 4.6374 56.543C5.34003 55.4122 6.32371 53.8573 7.8695 52.161C10.8205 48.9098 16.1605 44.6691 16.1605 44.6691L26.7 35.905C26.8405 35.7637 27.1216 35.4809 27.2621 35.1982L30.9158 27.8477Z"
        fill="url(#paint10_linear_2933_6930)"
      />
      <path
        d="M30.3531 31.948C33.3042 29.8276 37.5199 23.4663 41.1736 21.0631C44.8273 18.6599 52.8373 15.8327 56.2099 16.3981C59.723 16.8222 64.5009 17.2463 69.2788 21.0631C63.7983 21.9113 63.7983 21.9113 63.7983 21.9113L68.5762 27.1417C68.5762 27.1417 61.4094 22.4767 54.5236 24.5972C47.6378 26.7176 42.4383 29.9689 38.5036 32.3721C34.5689 34.7752 32.8826 35.9061 32.8826 35.9061L29.791 34.6339L30.3531 31.948Z"
        fill="url(#paint11_linear_2933_6930)"
      />
      <path
        d="M25.5757 36.1878C25.4351 36.4705 25.0136 36.6119 24.7325 36.6119C18.4088 37.0359 16.3009 36.6119 13.912 35.9051C11.5231 35.1983 8.9936 34.6329 7.16676 35.6224C5.90203 36.1878 4.63729 37.0359 3.93466 37.46C3.51308 37.7427 3.37256 38.1668 3.37256 38.5908L3.79414 48.0617C3.79414 48.7685 4.35624 49.1926 5.05887 49.1926L10.5394 48.7685C10.6799 48.7685 10.961 48.6271 11.1015 48.6271L22.7651 40.9939C22.9057 40.9939 22.9057 40.8525 23.0462 40.8525L28.2457 35.0569C28.3862 34.9156 28.3862 34.9156 28.3862 34.7742L32.883 25.5861C33.1641 25.162 33.0235 24.5966 32.7425 24.3139C31.4777 22.9003 28.2456 19.6491 26.4188 17.3874C25.1541 15.8325 24.1704 14.9844 23.4678 14.5603C22.9057 14.1362 22.203 14.2776 21.922 14.843L14.8957 23.4657C14.6146 23.8898 14.6146 24.3139 14.7552 24.7379C15.1767 25.5861 16.1604 26.9996 18.1278 28.2718C20.5167 29.8268 24.3109 32.2298 26.2783 33.3607C26.8404 33.7847 26.9809 34.4915 26.5593 35.0569L25.5757 36.1878Z"
        fill="url(#paint12_linear_2933_6930)"
      />
      <path
        d="M39.3469 74.636L28.1048 63.6102L34.4285 36.0458L29.3695 37.0353L18.8301 71.5262L30.6343 82.1279"
        fill="url(#paint13_linear_2933_6930)"
      />
      <path
        d="M30.2128 41.1351L25.2944 36.3288C25.2944 36.3288 27.4023 31.3811 28.8076 30.2502C30.4939 31.6639 32.6018 35.4807 34.2881 35.9047C33.0234 39.1561 31.4776 40.1456 31.4776 40.1456L30.2128 41.1351Z"
        fill="url(#paint14_linear_2933_6930)"
      />
      <path
        d="M15.1768 147.717H141.65"
        stroke="#B1B9CC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.91846 147.717H10.2584"
        stroke="#B1B9CC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2933_6930"
          x1="107.669"
          y1="117.299"
          x2="122.877"
          y2="146.838"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2933_6930"
          x1="15.3499"
          y1="107.766"
          x2="53.9292"
          y2="107.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2933_6930"
          x1="62.7699"
          y1="98.4235"
          x2="63.8999"
          y2="127.228"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2933_6930"
          x1="59.5687"
          y1="121.167"
          x2="52.7482"
          y2="103.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2933_6930"
          x1="105.926"
          y1="102.249"
          x2="106.831"
          y2="125.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2933_6930"
          x1="105.984"
          y1="131.265"
          x2="106.508"
          y2="144.633"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" stopOpacity="0" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2933_6930"
          x1="101.601"
          y1="123.261"
          x2="159.386"
          y2="123.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2933_6930"
          x1="25.522"
          y1="103.475"
          x2="20.359"
          y2="112.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" stopOpacity="0" />
          <stop offset="0.4964" stopColor="#FF8FC0" />
          <stop offset="1" stopColor="#F53689" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2933_6930"
          x1="63.704"
          y1="119.559"
          x2="56.883"
          y2="102.026"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2933_6930"
          x1="53.9095"
          y1="19.3632"
          x2="44.647"
          y2="38.6984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2933_6930"
          x1="9.02628"
          y1="62.5523"
          x2="44.178"
          y2="12.4376"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2933_6930"
          x1="40.7825"
          y1="42.0968"
          x2="60.1133"
          y2="14.5387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2933_6930"
          x1="-37.2189"
          y1="-40.7759"
          x2="-15.6539"
          y2="-11.2673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2933_6930"
          x1="21.4966"
          y1="81.1769"
          x2="36.4789"
          y2="52.0721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2933_6930"
          x1="27.5746"
          y1="39.3295"
          x2="32.2189"
          y2="32.7086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default OpenedGiftBox;
