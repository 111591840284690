import * as React from "react";

function SvgFile() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1716 3H9C7.11438 3 6.17157 3 5.58579 3.58579C5 4.17157 5 5.11438 5 7V17C5 18.8856 5 19.8284 5.58579 20.4142C6.17157 21 7.11438 21 9 21H15C16.8856 21 17.8284 21 18.4142 20.4142C19 19.8284 19 18.8856 19 17V8.82843C19 8.41968 19 8.2153 18.9239 8.03153C18.8478 7.84776 18.7032 7.70324 18.4142 7.41421L14.5858 3.58579C14.2968 3.29676 14.1522 3.15224 13.9685 3.07612C13.7847 3 13.5803 3 13.1716 3Z"
        stroke="#1FCF90"
        strokeWidth="2"
      />
      <path d="M9 13L15 13" stroke="#1FCF90" strokeWidth="2" strokeLinecap="round" />
      <path d="M9 17L13 17" stroke="#1FCF90" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M13 3V7C13 7.94281 13 8.41421 13.2929 8.70711C13.5858 9 14.0572 9 15 9H19"
        stroke="#1FCF90"
        strokeWidth="2"
      />
    </svg>
  );
}

export default SvgFile;
