/*@ts-nocheck*/
import * as React from "react";

function SvgLogoIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#0053F1" />
      <path
        d="M31.2613 29.9669L25.6101 26.7467V33.2187C25.6101 34.1027 24.884 34.8289 24 34.8289C23.116 34.8289 22.3899 34.1027 22.3899 33.2187V26.7467L16.7386 29.9669C16.4861 30.1248 16.2019 30.1879 15.9494 30.1879C15.3811 30.1879 14.8444 29.9038 14.5602 29.3671C14.1183 28.6094 14.4024 27.5991 15.1601 27.1571L20.7482 23.9684L15.1601 20.7798C14.4024 20.3378 14.1183 19.3591 14.5602 18.5698C15.0022 17.8121 15.9809 17.5279 16.7702 17.9699L22.4214 21.1902V14.7497C22.4214 13.8657 23.1476 13.1396 24.0315 13.1396C24.9155 13.1396 25.6417 13.8657 25.6417 14.7497V21.1902L31.2929 17.9699C32.0506 17.5279 33.0609 17.8121 33.5028 18.5698C33.9448 19.3275 33.6607 20.3378 32.903 20.7798L27.2518 23.9684L31.8296 26.5888H31.8612L42.911 32.903C44.1738 30.1879 44.9 27.1887 44.9 24C44.9 12.445 35.555 3.1 24 3.1C12.445 3.1 3.09998 12.445 3.09998 24C3.09998 35.555 12.445 44.9 24 44.9C31.1982 44.9 37.5439 41.2693 41.3009 35.7128L31.2929 29.9985L31.2613 29.9669Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgLogoIcon;
