export const Ledger = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M21.7819 0H9.89948V16.0349H25.9344V4.15253C25.9344 1.85174 24.0827 0 21.7819 0ZM6.196 0H4.15253C1.85174 0 0 1.85174 0 4.15253V6.196H6.196V0ZM0 9.89948H6.196V16.0955H0V9.89948ZM19.804 25.9344H21.8475C24.1483 25.9344 26 24.0827 26 21.7819V19.804H19.804V25.9344ZM9.89948 19.804H16.0955V26H9.89948V19.804ZM0 19.804V21.8475C0 24.1483 1.85174 26 4.15253 26H6.196V19.804H0Z"
        fill="#1D2028"
      />
    </svg>
  );
};
