import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useWeb3Modal } from "@web3modal/ethers/react";

const useWalletConnect = () => {
  const { open } = useWeb3Modal();
  const dispatch = useDispatch();
  return useCallback(async () => {
    await open();
  }, [open, dispatch]);
};

export default useWalletConnect;
