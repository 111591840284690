import * as React from "react";

function SvgEmptyFiles() {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="11" width="66" height="66">
        <path
          d="M11 19C11 15.2288 11 13.3431 12.1716 12.1716C13.3431 11 15.2288 11 19 11H69C72.7712 11 74.6569 11 75.8284 12.1716C77 13.3431 77 15.2288 77 19V69C77 72.7712 77 74.6569 75.8284 75.8284C74.6569 77 72.7712 77 69 77H19C15.2288 77 13.3431 77 12.1716 75.8284C11 74.6569 11 72.7712 11 69V19Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M49.3699 56.5068L15.8024 19.5826C15.2703 18.9972 15.0042 18.7046 14.6602 18.5364C14.6145 18.5141 14.5679 18.4935 14.5207 18.4747C14.1648 18.3333 13.7693 18.3333 12.9782 18.3333C8.89093 18.3333 6.8473 18.3333 5.66773 17.1111C5.51769 16.9557 5.3805 16.7883 5.25749 16.6107C4.29041 15.2143 4.6912 13.2104 5.49277 9.20252L7.88041 -2.73568C8.49612 -5.81422 8.80397 -7.35349 9.90989 -8.26012C11.0158 -9.16675 12.5856 -9.16675 15.7251 -9.16675H74.2922C78.1528 -9.16675 80.0831 -9.16675 81.2605 -7.95869C82.438 -6.75063 82.3885 -4.82098 82.2896 -0.961686L80.6666 62.3333L62.5465 47.2331C60.7578 45.7426 59.8635 44.9973 58.9167 45.1953C57.9699 45.3934 57.4493 46.4346 56.408 48.5171L52.6386 56.0559C52.0464 57.2404 51.7503 57.8326 51.2282 57.9047C50.7061 57.9767 50.2607 57.4867 49.3699 56.5068Z"
          fill="#7E869E"
          fillOpacity="0.25"
          stroke="#A6B4CE"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M11 47.6667V19C11 15.2288 11 13.3431 12.1716 12.1716C13.3431 11 15.2288 11 19 11H69C72.7712 11 74.6569 11 75.8284 12.1716C77 13.3431 77 15.2288 77 19V69C77 72.7712 77 74.6569 75.8284 75.8284C74.6569 77 72.7712 77 69 77H44"
        stroke="#A6B4CE"
        strokeWidth="1.2"
      />
      <circle cx="60.5" cy="27.5" r="5.5" fill="#A6B4CE" />
      <path
        d="M29.3334 58.6667V58.0667H29.9334V58.6667H29.3334ZM11.3749 73.8019C11.1161 74.0089 10.7385 73.967 10.5315 73.7082C10.3245 73.4495 10.3665 73.0719 10.6252 72.8649L11.3749 73.8019ZM28.7334 77.0001V58.6667H29.9334V77.0001H28.7334ZM29.3334 59.2667H11V58.0667H29.3334V59.2667ZM29.7082 59.1353L11.3749 73.8019L10.6252 72.8649L28.9586 58.1982L29.7082 59.1353Z"
        fill="#A6B4CE"
      />
    </svg>
  );
}

export default SvgEmptyFiles;
