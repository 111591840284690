import * as React from "react";

function SvgPlusRoundGrey() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9" stroke="#CCD2E3" strokeWidth="2" />
      <path d="M12 8L12 16" stroke="#CCD2E3" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 12L8 12" stroke="#CCD2E3" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export default SvgPlusRoundGrey;
