export const BinanceBig = () => {
  return (
    <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4138 0L32.9654 7.31034L28.4137 10.069L20.5517 5.37938L12.5516 10.069L7.9999 7.31034L20.4138 0ZM20.4138 9.2413L25.1034 11.9999L20.4138 14.7586L15.8621 11.9999L20.4138 9.2413ZM36.2759 9.2413L40.9655 11.9999V17.3793L36.2759 20.138V14.7586L31.7242 11.9999L36.2759 9.2413ZM4.5517 9.2413L9.24131 11.9999L4.5517 14.7586V20.138L0 17.3793V11.9999L4.5517 9.2413ZM32.9654 25.9308V31.3102L28.4137 34.0689V28.6895L32.9654 25.9308ZM7.86198 25.9308L12.4137 28.6895V34.0689L7.86198 31.3102V25.9308ZM28.2758 13.9309L32.9654 16.6896V22.0689L25.1034 26.7585V36.138L20.5517 38.8964L16 36.138V26.7585L7.86198 22.0689V16.6896L12.5516 13.9309L20.4138 18.6205L28.2758 13.9309ZM40.8274 21.2412V36.0001L28.2758 43.3102V37.9308L36.1378 33.2412V23.9999L40.8274 21.2412ZM0 21.2412L4.5517 23.9999V33.2412L12.4137 37.9308V43.3102L0 36.0001V21.2412ZM25.1034 39.862V45.2414L20.4138 48L15.8621 45.2414V39.862L20.4138 42.6206L25.1034 39.862Z"
        fill="#F3BA2F"
      />
    </svg>
  );
};
export const BinanceSmall = () => {
  return (
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4512 0L23.3366 4.87356L20.1144 6.71266L14.5488 3.58625L8.88543 6.71266L5.66323 4.87356L14.4512 0ZM14.4512 6.16087L17.771 7.99996L14.4512 9.83906L11.229 7.99996L14.4512 6.16087ZM25.6802 6.16087L29 7.99996V11.5862L25.6802 13.4253V9.83906L22.458 7.99996L25.6802 6.16087ZM3.22221 6.16087L6.54204 7.99996L3.22221 9.83906V13.4253L0 11.5862V7.99996L3.22221 6.16087ZM23.3366 17.2872V20.8735L20.1144 22.7126V19.1263L23.3366 17.2872ZM5.5656 17.2872L8.7878 19.1263V22.7126L5.5656 20.8735V17.2872ZM20.0168 9.28727L23.3366 11.1264V14.7126L17.771 17.839V24.092L14.5488 25.9309L11.3266 24.092V17.839L5.5656 14.7126V11.1264L8.88543 9.28727L14.4512 12.4137L20.0168 9.28727ZM28.9022 14.1608V24L20.0168 28.8734V25.2872L25.5824 22.1608V15.9999L28.9022 14.1608ZM0 14.1608L3.22221 15.9999V22.1608L8.7878 25.2872V28.8734L0 24V14.1608ZM17.771 26.5747V30.1609L14.4512 32L11.229 30.1609V26.5747L14.4512 28.4137L17.771 26.5747Z"
        fill="#F3BA2F"
      />
    </svg>
  );
};
