import * as React from "react";

export const SvgShieldBullet: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_411)" filter="url(#filter0_dd_2_411)">
        <path
          d="M10.3296 64.4565C7.38809 64.4565 4.99609 62.0645 4.99609 59.123C4.99609 56.1815 7.38809 53.7896 10.3296 53.7896C13.2711 53.7896 15.6631 56.1815 15.6631 59.123C15.6626 62.0645 13.2706 64.4565 10.3296 64.4565ZM10.3296 56.4565C8.86044 56.4565 7.66306 57.6539 7.66306 59.123C7.66306 60.5922 8.86044 61.7895 10.3296 61.7895C11.7987 61.7895 12.9961 60.5922 12.9961 59.123C12.9961 57.6539 11.7987 56.4565 10.3296 56.4565Z"
          fill="#0053F1"
        />
        <path
          d="M50.6626 64.4565C53.6041 64.4565 55.9961 62.0645 55.9961 59.123C55.9961 56.1815 53.6041 53.7896 50.6626 53.7896C47.7211 53.7896 45.3291 56.1815 45.3291 59.123C45.3296 62.0645 47.7216 64.4565 50.6626 64.4565ZM50.6626 56.4565C52.1318 56.4565 53.3291 57.6539 53.3291 59.123C53.3291 60.5922 52.1318 61.7895 50.6626 61.7895C49.1935 61.7895 47.9961 60.5922 47.9961 59.123C47.9961 57.6539 49.1935 56.4565 50.6626 56.4565Z"
          fill="#0053F1"
        />
        <path
          d="M10.3296 14.7501C7.38809 14.7501 4.99609 17.142 4.99609 20.0835C4.99609 23.025 7.38809 25.417 10.3296 25.417C13.2711 25.417 15.6631 23.025 15.6631 20.0835C15.6626 17.142 13.2706 14.7501 10.3296 14.7501ZM10.3296 22.75C8.86044 22.75 7.66306 21.5527 7.66306 20.0835C7.66306 18.6144 8.86044 17.417 10.3296 17.417C11.7987 17.417 12.9961 18.6144 12.9961 20.0835C12.9961 21.5527 11.7987 22.75 10.3296 22.75Z"
          fill="#0053F1"
        />
        <path
          d="M50.6626 14.7501C53.6041 14.7501 55.9961 17.142 55.9961 20.0835C55.9961 23.025 53.6041 25.417 50.6626 25.417C47.7211 25.417 45.3291 23.025 45.3291 20.0835C45.3296 17.142 47.7216 14.7501 50.6626 14.7501ZM50.6626 22.75C52.1318 22.75 53.3291 21.5527 53.3291 20.0835C53.3291 18.6144 52.1318 17.417 50.6626 17.417C49.1935 17.417 47.9961 18.6144 47.9961 20.0835C47.9961 21.5527 49.1935 22.75 50.6626 22.75Z"
          fill="#0053F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.7681 39.8194V31.1615L30.8134 25.053L19.2253 31.1396L19.2253 40.006C19.2241 41.6555 19.4841 43.2867 19.9886 44.8464C20.4596 46.3025 21.1437 47.6963 22.0275 48.9846C23.8534 51.6468 26.4523 53.7439 29.5071 55.0197L29.5221 55.0258L30.7398 55.5304L32.4594 54.8369C33.1288 54.5598 33.7765 54.2433 34.3992 53.8898C36.5921 52.6451 38.4748 50.9431 39.8997 48.9005C41.7757 46.211 42.7738 43.0518 42.7681 39.8214L42.7681 39.8194ZM41.9918 50.2331C40.3573 52.5761 38.198 54.5281 35.6833 55.9555C34.8871 56.4073 34.0554 56.8067 33.1934 57.1492L31.1355 57.9786C30.8779 58.0838 30.5861 58.0838 30.3281 57.9786L28.5277 57.2325C26.9079 56.5588 25.3991 55.6838 24.0379 54.6364C22.4464 53.4118 21.0562 51.9515 19.9247 50.3018C18.9112 48.8243 18.1266 47.2259 17.5865 45.5559C17.0078 43.7671 16.7097 41.8963 16.711 40.0044V30.3076C16.7078 29.9535 16.9044 29.6254 17.2256 29.4484L30.3124 22.5745C30.6185 22.4156 30.9884 22.4156 31.2949 22.5745L44.7473 29.4484C45.0735 29.6186 45.278 29.9432 45.2823 30.2977V39.8174C45.2889 43.5237 44.1438 47.148 41.9918 50.2331Z"
          fill="#0053F1"
        />
        <path
          d="M35.5931 36.4089L30.1211 41.579L27.6705 38.8598L27.6702 38.8595C27.4304 38.5898 27.0649 38.4685 26.7111 38.5413C26.3574 38.6141 26.0697 38.8701 25.9558 39.2127C25.842 39.5553 25.9197 39.9325 26.1595 40.2025L29.3153 43.6938C29.4956 43.8962 29.7497 44.017 30.0202 44.0297H30.0539C30.3148 44.0348 30.5675 43.9386 30.7588 43.7611L36.9696 37.8861C37.2335 37.6402 37.3461 37.2723 37.2651 36.9208C37.1841 36.5693 36.9218 36.2878 36.5769 36.1822C36.232 36.0766 35.857 36.163 35.5931 36.4089L35.5931 36.4089Z"
          fill="#0053F1"
        />
        <path d="M48.9178 55.4463L40.7008 48.592L38.7791 50.8957L46.9961 57.75L48.9178 55.4463Z" fill="#0053F1" />
        <path d="M12.0748 55.4463L20.2918 48.592L22.2135 50.8957L13.9965 57.75L12.0748 55.4463Z" fill="#0053F1" />
        <path d="M20.2912 29.908L12.0742 23.0537L13.9959 20.75L22.2129 27.6043L20.2912 29.908Z" fill="#0053F1" />
        <path d="M48.9182 23.0537L40.7012 29.908L38.7795 27.6043L46.9965 20.75L48.9182 23.0537Z" fill="#0053F1" />
      </g>
      <defs>
        <filter
          id="filter0_dd_2_411"
          x="0.996094"
          y="0"
          width="59"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2_411" result="effect2_dropShadow_2_411" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2_411" result="shape" />
        </filter>
        <clipPath id="clip0_2_411">
          <rect width="51" height="80" fill="white" transform="translate(4.99609)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgShieldBullet;
