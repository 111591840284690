import * as React from "react";

function Gift() {
  return (
    <svg width="147" height="182" viewBox="0 0 147 182" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M94.9199 44.2899C107.053 39.5611 124.362 29.7774 131.48 25.5378C132.451 24.8855 133.745 26.027 133.26 27.1684C130.995 32.5494 129.215 34.9954 129.215 34.9954C129.215 34.9954 132.936 35.8107 135.363 37.9305C136.333 38.7458 136.171 40.2134 135.039 40.7025C128.73 43.8007 120.641 45.2683 120.641 45.2683L98.9642 47.062L94.9199 44.2899Z"
        fill="#DB1A74"
      />
      <path
        d="M51.08 44.2899C38.9472 39.5611 21.6377 29.7774 14.5198 25.5378C13.5492 24.8855 12.255 26.027 12.7404 27.1684C15.0051 32.5494 16.7846 34.9954 16.7846 34.9954C16.7846 34.9954 13.0639 35.8107 10.6373 37.9305C9.66671 38.7458 9.82848 40.2134 10.9609 40.7025C17.2699 43.8007 25.3585 45.2683 25.3585 45.2683L47.0358 47.062L51.08 44.2899Z"
        fill="#DB1A74"
      />
      <path
        d="M121.288 22.4389C120.803 25.374 119.509 27.983 117.729 30.2659C114.009 34.9947 108.509 38.0929 103.332 40.7019C99.4494 42.6586 95.4051 44.4523 91.1991 45.5937C88.449 46.246 83.5958 44.7784 80.3604 43.4739C78.4192 42.6586 77.125 42.1694 77.125 42.1694V38.2559C77.9339 31.8965 78.4192 25.2109 81.0075 19.3407C83.2723 13.9597 86.993 9.0678 91.8462 5.64349C96.5375 2.21919 102.199 -1.04206 108.347 0.914689C115.788 3.36062 121.288 10.6984 121.774 18.5254C121.612 19.8299 121.45 21.1344 121.288 22.4389Z"
        fill="url(#paint0_linear_2924_6940)"
      />
      <path
        d="M121.289 22.4396C120.804 25.3747 119.509 27.9837 117.73 30.2666C114.009 34.9954 108.509 38.0936 103.332 40.7026C99.4498 42.6593 95.4055 44.453 91.1995 45.5944C88.4494 46.2467 83.5963 44.7791 80.3608 43.4746C85.6993 41.6809 91.0377 39.8872 96.3762 38.0936C101.391 36.4629 106.406 34.6693 110.936 31.8972C115.465 29.1251 119.348 25.2116 121.127 20.1567C121.289 19.6675 121.451 19.0153 121.612 18.363C121.612 19.8306 121.451 21.1351 121.289 22.4396Z"
        fill="#E9368B"
      />
      <path
        d="M85.3755 33.528C83.4343 34.0171 81.6548 32.2235 82.3019 30.2667C85.052 20.3199 88.6109 16.0803 92.0081 12.9821C95.0818 10.2101 100.258 7.76413 104.95 6.78575C106.729 6.45963 108.185 7.92719 108.023 9.72087C107.376 16.0803 104.788 22.2767 100.097 25.864C94.1111 30.5928 88.7727 32.7126 85.3755 33.528Z"
        fill="#DB1A74"
      />
      <path
        d="M69.6838 38.0936V42.007C69.6838 42.007 69.3603 42.1701 68.875 42.3332C66.4484 43.4746 59.1687 46.4097 55.6097 45.4314C51.4037 44.453 47.3594 42.4962 43.4769 40.5395C38.3003 37.9305 32.6383 34.8323 29.0793 30.1035C27.2998 27.8206 26.0057 25.2116 25.5204 22.2765C25.035 18.5261 25.5204 14.9387 27.1381 11.5144C27.7852 10.2099 28.4322 8.90542 29.2411 7.76398C31.6677 4.50274 34.9031 1.89375 38.7856 0.589249C44.7711 -1.3675 50.4331 1.89375 55.2862 5.31805C60.1393 8.74236 63.8601 13.4712 66.1249 19.0153C68.2279 25.0486 68.875 31.7341 69.6838 38.0936Z"
        fill="url(#paint1_linear_2924_6940)"
      />
      <path
        d="M68.7128 42.4962C66.2863 43.6377 59.0066 46.5728 55.4476 45.5944C51.2416 44.6161 47.1973 42.6593 43.3148 40.7026C38.1381 38.0936 32.4762 34.9954 28.9172 30.2666C27.1377 27.9837 25.8436 25.3747 25.3583 22.4396C24.8729 18.6892 25.3583 15.1018 26.976 11.6775C26.6524 13.4712 26.6524 15.1018 26.6524 15.9171C26.976 21.2982 29.2408 26.5161 33.1233 30.2666C37.0058 34.017 42.0207 36.1368 47.1973 37.2783C52.374 38.4197 57.7124 38.7458 63.0509 39.3981C65.3157 39.5611 68.0658 40.3764 68.7128 42.4962Z"
        fill="#E9368B"
      />
      <path
        d="M73.5663 58.1502C79.9097 58.1502 85.052 52.9668 85.052 46.5728C85.052 40.1787 79.9097 34.9954 73.5663 34.9954C67.2229 34.9954 62.0806 40.1787 62.0806 46.5728C62.0806 52.9668 67.2229 58.1502 73.5663 58.1502Z"
        fill="#DB1A74"
      />
      <path
        d="M61.1099 33.528C63.0512 34.0171 64.8307 32.2235 64.1836 30.2667C61.4335 20.3199 57.8745 16.0803 54.4773 12.9821C51.4037 10.2101 46.227 7.76413 41.5357 6.78575C39.7562 6.45963 38.3002 7.92719 38.462 9.72087C39.1091 16.0803 41.6974 22.2767 46.3888 25.864C52.5361 30.5928 57.7127 32.7126 61.1099 33.528Z"
        fill="#DB1A74"
      />
      <g filter="url(#filter0_d_2924_6940)">
        <path
          d="M124.686 60.5962V140.823C124.686 145.062 121.289 148.487 117.083 148.487H29.7263C25.5202 148.487 22.123 145.062 22.123 140.823V60.5962C22.123 56.3566 25.5202 52.9323 29.7263 52.9323H117.083C121.289 52.9323 124.686 56.3566 124.686 60.5962Z"
          fill="white"
        />
      </g>
      <path
        d="M124.686 60.5962V78.37H22.123V60.5962C22.123 56.3566 25.5202 52.9323 29.7263 52.9323H117.083C121.289 52.9323 124.686 56.3566 124.686 60.5962Z"
        fill="#DDE1F0"
      />
      <path
        d="M130.024 47.0618V67.6077C130.024 70.0536 128.083 72.1734 125.495 72.1734H21.3143C18.8877 72.1734 16.7847 70.2167 16.7847 67.6077V47.0618C16.7847 44.6159 18.7259 42.4961 21.3143 42.4961H125.656C128.083 42.4961 130.024 44.6159 130.024 47.0618Z"
        fill="white"
      />
      <path d="M86.0223 61.4114H58.1978V148.65H86.0223V61.4114Z" fill="url(#paint2_linear_2924_6940)" />
      <path opacity="0.6" d="M86.0223 61.4114H58.1978V78.3698H86.0223V61.4114Z" fill="#DB1A74" />
      <path d="M87.9639 42.4961H56.2568V72.1734H87.9639V42.4961Z" fill="url(#paint3_linear_2924_6940)" />
      <defs>
        <filter
          id="filter0_d_2924_6940"
          x="0.123047"
          y="41.9323"
          width="146.562"
          height="139.554"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.26 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2924_6940" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2924_6940" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_2924_6940"
          x1="76.9603"
          y1="22.9896"
          x2="121.485"
          y2="22.9896"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2924_6940"
          x1="25.1019"
          y1="22.9888"
          x2="69.6163"
          y2="22.9888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2924_6940"
          x1="58.24"
          y1="104.928"
          x2="86.002"
          y2="104.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2924_6940"
          x1="72.1251"
          y1="56.4544"
          x2="72.1251"
          y2="70.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />
          <stop offset="1" stopColor="#F53689" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Gift;
