import * as React from "react";

function BigCLS() {
  return (
    <svg width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M147.848 73.9977C147.848 83.1899 146.017 92.3822 142.813 100.655C135.031 121.338 118.095 137.424 97.0394 144.318C89.7156 146.616 81.9342 147.995 74.1527 147.995C33.4145 148.455 0 114.903 0 73.9977C0.457733 33.0921 33.4145 0 74.1527 0C114.891 0 147.848 33.0921 147.848 73.9977Z"
        fill="url(#paint0_linear_2924_7096)"
      />
      <path
        d="M96.2356 92.2719L78.9305 82.4109V102.23C78.9305 104.937 76.7069 107.16 74 107.16C71.293 107.16 69.0695 104.937 69.0695 102.23V82.4109L51.7644 92.2719C50.9909 92.7553 50.1208 92.9486 49.3474 92.9486C47.6073 92.9486 45.9637 92.0786 45.0937 90.4351C43.7402 88.1148 44.6103 85.0211 46.9305 83.6677L64.0423 73.9033L46.9305 64.139C44.6103 62.7855 43.7402 59.7885 45.0937 57.3716C46.4471 55.0514 49.4441 54.1813 51.861 55.5347L69.1662 65.3958V45.6737C69.1662 42.9668 71.3897 40.7432 74.0967 40.7432C76.8036 40.7432 79.0272 42.9668 79.0272 45.6737V65.3958L96.3323 55.5347C98.6526 54.1813 101.746 55.0514 103.1 57.3716C104.453 59.6918 103.583 62.7855 101.263 64.139L83.9577 73.9033L97.9758 81.9275H98.0725L131.909 101.263C135.776 92.9486 138 83.7644 138 74C138 38.6163 109.384 10 74 10C38.6163 10 10 38.6163 10 74C10 109.384 38.6163 138 74 138C96.0423 138 115.474 126.882 126.979 109.867L96.3323 92.3686L96.2356 92.2719Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2924_7096"
          x1="34.4105"
          y1="13.4092"
          x2="116.387"
          y2="137.619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83A6FF" />
          <stop offset="1" stopColor="#5A78FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BigCLS;
