export const Defi = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 66.6667C54.7276 66.6667 66.6666 54.7276 66.6666 40C66.6666 25.2724 54.7276 13.3333 40 13.3333C25.2724 13.3333 13.3333 25.2724 13.3333 40C13.3333 54.7276 25.2724 66.6667 40 66.6667Z"
        stroke="#0053F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 10L20 20" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70 10L60 20" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 70L20 60" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70 70L60 60" stroke="#0053F1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Defi;
