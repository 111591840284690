import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { AppConfig } from "../../config";
import { isFull, removeZeros, rpcUrl } from "../common";
import { waitReady } from "@polkadot/wasm-crypto";
import { HttpProvider } from "@polkadot/rpc-provider";
import { ApiPromise } from "@polkadot/api";
import Big from "big.js";
import { useEffect, useState } from "react";
import { LocalStorage } from "../localStorage";
const isDev = !AppConfig.isProd;
// 1. Get projectId
const projectId = AppConfig.walletConnectDappId;

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  // rpcUrl: AppConfig.rpcUrl,
  rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create a metadata object
const metadata = {
  name: "coldstack",
  description: "coldstack",
  url: isDev ? "http://localhost:3000" : "https://coldstack.io/", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
export const runWeb3Modal = () =>
  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

const getBalanceCLS = (value: string) => {
  Big.DP = 10;
  Big.RM = Big.roundHalfUp;

  let num = new Big(0);

  if (Number(value) > 0) {
    num = Big(value).div(Big(10).pow(18)).toFixed(4);
    num = removeZeros(num?.toString());
  }

  return num?.toString();
};

export const getWeb3ModalBalance = async (address: string, cb) => {
  let timerId;
  if (isFull(address) && rpcUrl) {
    try {
      await waitReady();
      const provider = new HttpProvider(rpcUrl);
      const api = await ApiPromise.create({ provider });
      const data = await api.query.coldStack.balances(address);
      const value = data?.toString();
      let balanceCLS = getBalanceCLS(value);

      cb(balanceCLS);

      timerId = setInterval(async () => {
        try {
          const data = await api.query.coldStack.balances(address);
          const value = data?.toString();

          balanceCLS = getBalanceCLS(value);
          clearInterval(timerId);
          cb(balanceCLS);
        } catch (err) {
          console.error(err);
        }
      }, 6000);
    } catch (err) {
      console.error("dsd", err);
    }
  }
};

export const useCheckConnection = () => {
  const { isConnected } = useWeb3ModalAccount();
  const [isUserConnected, setIsUserConnected] = useState<null | boolean>(false);
  const [checkCount, setCheckCount] = useState(0);
  const isWalletConnect = LocalStorage.getItem<boolean>("walletconnect");

  const checkCountFunc = () => (checkCount === 3 ? false : null);

  useEffect(() => {
    if (!isWalletConnect) {
      setIsUserConnected(null);
      return;
    }

    if (isConnected) {
      setIsUserConnected(true);
    } else if (checkCount < 3) {
      const timer = setTimeout(() => {
        setCheckCount(checkCount + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isConnected, checkCount, isWalletConnect]);

  return !isUserConnected ? checkCountFunc() : isUserConnected;
};
