import Title from "../../../../components/UI/Title/Title";
import style from "./whatWeDo.module.scss";
import GameFi from "../../../../icons/GameFi";
import Metaverse from "../../../../icons/Metaverse";
import Defi from "../../../../icons/Defi";
import MediaArt from "../../../../icons/MediaArt";
import ButtonOval from "../../../../components/UI/ButtonOval/ButtonOval";

export const WhatWeDo = () => {
  const blocks = [
    {
      icon: <GameFi />,
      text: "GameFi",
    },
    {
      icon: <Metaverse />,
      text: "Metaverse",
    },
    {
      icon: <Defi />,
      text: "Defi",
    },
    {
      icon: <MediaArt />,
      text: "Media & Art",
    },
  ];

  return (
    <div className={style.wrapper}>
      <Title textAlign="center" type="h2">
        What We Do
      </Title>
      <div className={style.subtitle}>
        ColdStack can help you take ownership of your data, reduce costs, and improve security by making it easy for
        anyone to access the Web3 cloud ecosystem regardless of your technological prowess{" "}
      </div>

      <div className={style.wrapperBullets}>
        {blocks.map((item, index) => {
          return (
            <div key={index} className={style.iconBlock}>
              <div className={style.iconWrapper}>{item.icon}</div>
              <div className={style.iconText}>{item.text}</div>
            </div>
          );
        })}
      </div>
      <div className={style.secondaryTextWrapper}>
        <div className={style.secondaryText}>Our mission is to make Web3 Cloud storage simple for everyone</div>
      </div>
      <div className={style.buttonsBlock}>
        <ButtonOval href="/migration/" size="big" color="blue" text="Need help with migration?" reactRouterNav />
        <ButtonOval href="/auth/" size="big" color="white" text="Get Started" reactRouterNav />
      </div>
    </div>
  );
};
