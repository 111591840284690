import * as React from "react";

function SvgSuccess() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 70C56.5685 70 70 56.5685 70 40C70 23.4315 56.5685 10 40 10C23.4315 10 10 23.4315 10 40C10 56.5685 23.4315 70 40 70ZM54.1016 30.6402C54.4551 30.2159 54.3978 29.5853 53.9735 29.2318C53.5492 28.8782 52.9187 28.9355 52.5651 29.3598L37.3005 47.6774C36.9538 48.0934 36.7589 48.3238 36.6032 48.4653L36.5973 48.4707L36.5909 48.4659C36.4231 48.339 36.2081 48.1272 35.8252 47.7443L27.3738 39.2929C26.9832 38.9024 26.3501 38.9024 25.9596 39.2929C25.569 39.6834 25.569 40.3166 25.9596 40.7071L34.411 49.1585L34.4518 49.1993C34.7783 49.526 35.0924 49.8402 35.3846 50.0611C35.712 50.3087 36.1407 50.5415 36.6901 50.5165C37.2395 50.4916 37.6454 50.221 37.949 49.9448C38.22 49.6983 38.5044 49.3569 38.8 49.0021L38.8369 48.9577L54.1016 30.6402Z"
        fill="#1FCF90"
      />
    </svg>
  );
}
export default SvgSuccess;
