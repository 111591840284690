import * as React from "react";

function SvgColdstackLogoBlueBackgroundLogo() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1692 15.426L12.9245 13.577V17.2931C12.9245 17.8006 12.5076 18.2175 12 18.2175C11.4924 18.2175 11.0755 17.8006 11.0755 17.2931V13.577L7.83082 15.426C7.6858 15.5166 7.52266 15.5529 7.37764 15.5529C7.05136 15.5529 6.7432 15.3897 6.58006 15.0816C6.32628 14.6465 6.48943 14.0665 6.92447 13.8127L10.1329 11.9819L6.92447 10.1511C6.48943 9.89728 6.32628 9.33535 6.58006 8.88218C6.83384 8.44713 7.39577 8.28399 7.84894 8.53776L11.0937 10.3867V6.68882C11.0937 6.18127 11.5106 5.76435 12.0181 5.76435C12.5257 5.76435 12.9426 6.18127 12.9426 6.68882V10.3867L16.1873 8.53776C16.6224 8.28399 17.2024 8.44713 17.4562 8.88218C17.71 9.31722 17.5468 9.89728 17.1118 10.1511L13.8671 11.9819L16.4955 13.4864H16.5136L22.858 17.1118C23.5831 15.5529 24 13.8308 24 12C24 5.36556 18.6344 0 12 0C5.36556 0 0 5.36556 0 12C0 18.6344 5.36556 24 12 24C16.1329 24 19.7764 21.9154 21.9335 18.7251L16.1873 15.4441L16.1692 15.426Z"
        fill="#0053F1"
      />
    </svg>
  );
}

export default SvgColdstackLogoBlueBackgroundLogo;
