import * as React from "react";

function SvgLogotype({ color = "white" }) {
  return (
    <svg width="144" height="24" viewBox="0 0 144 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.689 19.9096C36.2387 19.4578 35.8447 19.2037 35.6196 19.2037C34.8315 19.8814 33.7621 20.2203 32.2986 20.2203C29.5404 20.2203 27.9644 18.3846 27.9644 15.7582C27.9644 13.0472 29.5686 11.2115 32.1859 11.2115C33.2273 11.2115 34.0153 11.4657 34.5501 11.9175V13.3861C34.5501 13.612 34.6908 13.8379 35.0567 14.2051C35.3944 14.5156 35.6476 14.6569 35.8165 14.6569C36.0417 14.6569 36.2669 14.5156 36.5765 14.2051C36.9142 13.8661 37.0831 13.612 37.0831 13.3861V10.675C37.0831 10.2231 36.5765 9.71479 35.6196 9.20646C34.6626 8.66986 33.5087 8.44392 32.1578 8.44392C29.9626 8.44392 28.1333 9.12173 26.7542 10.5055C25.3751 11.8611 24.6434 13.612 24.6434 15.7301C24.6434 16.0689 24.6716 16.3796 24.6997 16.6902L12.4289 9.68652L17.4104 6.83423C18.0859 6.43887 18.3391 5.56341 17.9451 4.85739C17.5511 4.17962 16.6786 3.92545 15.975 4.32082L10.9373 7.20136V1.44027C10.9373 0.649535 10.29 0 9.50194 0C8.7139 0 8.06659 0.649535 8.06659 1.44027V7.20136L3.02882 4.32082C2.32521 3.92545 1.45275 4.17962 1.05874 4.85739C0.664721 5.53517 0.918017 6.43887 1.59347 6.83423L6.6031 9.68652L1.62162 12.5389C0.946161 12.9342 0.692865 13.8097 1.08688 14.5157C1.34018 14.9675 1.84677 15.2499 2.32521 15.2499C2.57851 15.2499 2.80366 15.1935 3.02882 15.0523L8.06659 12.1717V17.9611C8.06659 18.7518 8.7139 19.4013 9.50194 19.4013C10.29 19.4013 10.9373 18.7518 10.9373 17.9611V12.1435L27.5141 21.6041L28.8087 22.3383L28.8369 22.3666C28.9213 22.423 29.0338 22.4513 29.1183 22.4795C30.0189 22.8184 31.032 22.9878 32.1578 22.9878C34.3249 22.9878 36.098 22.3101 37.4208 20.9828C37.4489 20.7568 37.1956 20.3615 36.689 19.9096Z"
        fill={color}
      />
      <path
        d="M46.1173 8.44409C43.9783 8.44409 42.2052 9.1219 40.8262 10.5339C39.4471 11.8895 38.7435 13.6404 38.7435 15.7584C38.7435 17.8482 39.419 19.571 40.8262 20.9829C42.2052 22.3385 43.9783 23.0446 46.1173 23.0446C48.2562 23.0446 50.0293 22.3667 51.4084 20.9829C52.7874 19.5991 53.5191 17.8482 53.5191 15.7584C53.5191 13.6404 52.8155 11.9177 51.4084 10.5339C50.0011 9.1219 48.2562 8.44409 46.1173 8.44409ZM48.9879 19.0061C48.2844 19.8534 47.3274 20.2487 46.1173 20.2487C44.9071 20.2487 43.9502 19.8534 43.2466 19.0061C42.5429 18.1589 42.1771 17.0858 42.1771 15.7584C42.1771 14.4312 42.5429 13.3298 43.2466 12.5108C43.9502 11.6636 44.9071 11.2682 46.1173 11.2682C47.3274 11.2682 48.2844 11.6636 48.9879 12.5108C49.7197 13.358 50.1137 14.4312 50.1137 15.7584C50.1137 17.0858 49.7479 18.1589 48.9879 19.0061Z"
        fill={color}
      />
      <path
        d="M59.148 0.564728C58.8384 0.22584 58.585 0.0563965 58.388 0.0563965H54.4479C54.2228 0.0563965 53.9976 0.1976 53.6317 0.564728C53.3221 0.875375 53.1814 1.12954 53.1814 1.32723C53.1814 1.52491 53.3221 1.77907 53.6317 2.1462C53.9694 2.45685 54.2228 2.59805 54.4479 2.59805H56.4461V22.7336H59.6264V1.32723C59.6545 1.1013 59.4857 0.875375 59.148 0.564728Z"
        fill={color}
      />
      <path
        d="M76.0062 19.2036V1.32723C76.0062 1.1013 75.8373 0.875375 75.4996 0.564728C75.19 0.22584 74.9367 0.0563965 74.7397 0.0563965H71.7001C71.475 0.0563965 71.2499 0.1976 70.884 0.564728C70.5744 0.875375 70.4337 1.12954 70.4337 1.32723C70.4337 1.52491 70.5744 1.77907 70.884 2.14621C71.2217 2.45685 71.475 2.59805 71.7001 2.59805H72.8259V8.89572C71.8972 8.58505 70.9965 8.44385 70.096 8.44385C67.9007 8.44385 66.0995 9.12166 64.6923 10.5336C63.2569 11.8893 62.5533 13.6684 62.5533 15.8429C62.5533 17.9045 63.1726 19.5989 64.439 20.9544C65.7054 22.31 67.3097 23.016 69.2235 23.016C70.6869 23.016 71.9535 22.5642 73.0229 21.6605C73.3888 22.1688 74.0643 22.7336 74.402 22.7336H76.3157V22.3665L76.2314 22.1123C76.1187 21.8299 76.0062 20.672 76.0062 19.2036ZM72.7978 19.373C72.0379 19.966 71.1092 20.2484 70.0115 20.2484C67.5067 20.2484 65.9306 18.4129 65.9306 15.7865C65.9306 13.0471 67.5348 11.2115 70.2367 11.2115C71.1935 11.2115 72.066 11.4091 72.7978 11.8045V19.373Z"
        fill={color}
      />
      <path
        d="M84.7303 14.4027C83.8867 14.0638 83.3795 13.8096 83.1549 13.6967C82.4232 13.3296 82.0287 12.8777 82.0287 12.4258C82.0287 11.5786 82.8168 11.0703 84.1399 11.0703C85.2938 11.0703 86.1938 11.381 86.8415 12.0305C87.0669 12.0305 87.4327 11.7763 87.8828 11.3244C88.3328 10.8726 88.5867 10.4772 88.5867 10.2796C87.4327 9.06524 85.9129 8.47217 84.0549 8.47217C80.7343 8.47217 79.0456 9.99717 79.0456 12.5953C79.0456 14.6851 80.1151 15.7018 83.0986 16.9444L84.7303 17.6504C85.6311 18.0458 86.0534 18.5258 86.0534 18.9777C86.0534 19.8813 85.0962 20.4461 83.6891 20.4461C82.3946 20.4461 81.297 20.0225 80.4811 19.1754C80.2557 19.1754 79.89 19.4295 79.4115 19.9096C78.9331 20.418 78.7079 20.7851 78.7079 20.9828C80.0307 22.3101 81.7478 22.9878 83.8018 22.9878C87.151 22.9878 89.0645 21.4063 89.0645 18.8365C89.0645 17.7916 88.7272 16.9444 88.0796 16.3513C87.4605 15.7582 86.3065 15.0805 84.7303 14.4027Z"
        fill={color}
      />
      <path
        d="M100.323 20.1641C99.9282 19.7405 99.5909 19.5146 99.3655 19.5146C98.8306 20.0511 98.1552 20.277 97.2544 20.277C96.2417 20.277 95.7067 19.7122 95.7067 18.6391V11.2965H100.181V8.75482H95.7067V3.64331H94.8059L92.6956 4.80118V8.75482H91.1757C90.9503 8.75482 90.6694 8.8961 90.3591 9.20669C90.0495 9.51736 89.9091 9.79976 89.9091 10.0257C89.9091 10.2516 90.0495 10.4776 90.3591 10.8447C90.6972 11.1553 90.9503 11.2965 91.1757 11.2965H92.4988V19.2039C92.4988 21.7455 93.8496 23.0163 96.5512 23.0163C98.2115 23.0163 99.675 22.3951 100.942 21.1243C100.969 20.8984 100.745 20.5594 100.323 20.1641Z"
        fill={color}
      />
      <path
        d="M113.635 19.2038V13.3298C113.635 10.1386 111.749 8.44409 108.062 8.44409C105.839 8.44409 104.009 9.1219 102.602 10.5339C102.602 10.8164 102.855 11.1834 103.334 11.6918C103.84 12.1436 104.206 12.3978 104.459 12.3978C105.36 11.5224 106.458 11.0988 107.781 11.0988C109.413 11.0988 110.455 11.9177 110.455 13.4145V14.4876C109.751 14.4029 109.103 14.3746 108.484 14.3746C104.432 14.3746 102.264 16.0126 102.264 18.9214C102.264 21.5477 104.15 23.0163 106.88 23.0163C108.372 23.0163 109.638 22.5644 110.651 21.6325C111.045 22.1691 111.72 22.7622 112.086 22.7622H114.001V22.395L113.916 22.1408C113.747 21.8301 113.635 20.6723 113.635 19.2038ZM110.455 19.3167C109.723 20.051 108.793 20.4463 107.668 20.4463C106.177 20.4463 105.445 19.8534 105.445 18.6672C105.445 17.3399 106.458 16.6903 108.513 16.6903C109.159 16.6903 109.807 16.7469 110.455 16.8315V19.3167Z"
        fill={color}
      />
      <path
        d="M128.438 19.9098C127.96 19.4579 127.594 19.2038 127.369 19.2038C126.581 19.8815 125.511 20.2205 124.048 20.2205C121.29 20.2205 119.713 18.3848 119.713 15.7584C119.713 13.0474 121.318 11.2117 123.935 11.2117C124.977 11.2117 125.764 11.4659 126.299 11.9177V13.3862C126.299 13.6122 126.44 13.8381 126.806 14.2052C127.144 14.5158 127.397 14.657 127.594 14.657C127.819 14.657 128.044 14.5158 128.354 14.2052C128.691 13.8663 128.86 13.6122 128.86 13.3862V10.6751C128.86 10.2233 128.354 9.71496 127.397 9.20663C126.44 8.67003 125.287 8.44409 123.935 8.44409C121.74 8.44409 119.911 9.1219 118.532 10.5057C117.125 11.8612 116.421 13.6122 116.421 15.7302C116.421 17.8765 117.125 19.5991 118.532 20.9829C119.911 22.3385 121.74 23.0446 123.991 23.0446C126.159 23.0446 127.932 22.3667 129.255 21.0394C129.17 20.757 128.917 20.3617 128.438 19.9098Z"
        fill={color}
      />
      <path
        d="M138.992 15.5322L143.439 10.3643C143.439 10.1101 143.13 9.74292 142.51 9.20639C141.919 8.66979 141.497 8.44385 141.244 8.44385L136.347 14.092L135.108 14.2614V1.32723C135.108 1.1013 134.968 0.875375 134.602 0.564728C134.292 0.22584 134.039 0.0563965 133.814 0.0563965H130.774C130.549 0.0563965 130.324 0.1976 129.958 0.564728C129.648 0.875375 129.508 1.12954 129.508 1.32723C129.508 1.52491 129.648 1.77907 129.958 2.1462C130.296 2.45685 130.549 2.59805 130.774 2.59805H131.9V22.7336H135.08V17.0572L136.234 16.916L141.553 23.0443C141.806 23.0443 142.229 22.7618 142.82 22.2253C143.41 21.6605 143.72 21.2651 143.72 21.011L138.992 15.5322Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgLogotype;
