export const MediaArt = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65 48.3667L65.0333 48.33M73.3333 42V68C73.3333 68.2626 73.2816 68.5227 73.1811 68.7654C73.0806 69.008 72.9333 69.2285 72.7475 69.4142C72.5618 69.5999 72.3413 69.7473 72.0987 69.8478C71.856 69.9483 71.596 70 71.3333 70H45.3333C44.8029 70 44.2942 69.7893 43.9191 69.4142C43.544 69.0391 43.3333 68.5304 43.3333 68V42C43.3333 41.4696 43.544 40.9609 43.9191 40.5858C44.2942 40.2107 44.8029 40 45.3333 40H71.3333C71.596 40 71.856 40.0517 72.0987 40.1522C72.3413 40.2528 72.5618 40.4001 72.7475 40.5858C72.9333 40.7715 73.0806 40.992 73.1811 41.2346C73.2816 41.4773 73.3333 41.7374 73.3333 42Z"
        stroke="#0053F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.3333 60.6667L55 56.6667L73.3333 63.3333M6.66667 33.3333V12C6.66667 11.4696 6.87739 10.9609 7.25246 10.5858C7.62753 10.2107 8.13624 10 8.66667 10H29.26C29.7367 9.99996 30.1977 10.1702 30.56 10.48L41.1067 19.52C41.469 19.8298 41.93 20 42.4067 20H71.3333C71.596 20 71.8561 20.0517 72.0987 20.1522C72.3414 20.2528 72.5618 20.4001 72.7476 20.5858C72.9333 20.7715 73.0806 20.992 73.1811 21.2346C73.2816 21.4773 73.3333 21.7374 73.3333 22V30M6.66667 33.3333V61.3333C6.66667 61.8638 6.87739 62.3725 7.25246 62.7475C7.62753 63.1226 8.13624 63.3333 8.66667 63.3333H33.3333M6.66667 33.3333H33.3333"
        stroke="#0053F1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MediaArt;
