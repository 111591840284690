import * as React from "react";

function SvgHeadPhones() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="16" y="12" width="4" height="7" rx="2" stroke="#5A5D65" strokeWidth="2" strokeLinejoin="round" />
      <rect x="4" y="12" width="4" height="7" rx="2" stroke="#5A5D65" strokeWidth="2" strokeLinejoin="round" />
      <path d="M4 13V16" stroke="#5A5D65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 13V16" stroke="#5A5D65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20 13C20 10.6131 19.1571 8.32387 17.6569 6.63604C16.1566 4.94821 14.1217 4 12 4C9.87827 4 7.84344 4.94821 6.34315 6.63604C4.84286 8.32387 4 10.6131 4 13"
        stroke="#5A5D65"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHeadPhones;
