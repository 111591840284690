import * as React from "react";

export const SvgEqualizerBullet: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="50" height="80" viewBox="0 0 50 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_376)">
        <path
          d="M46.1437 60.0197L46.1437 36.4677C48.0639 35.0597 49.3437 32.8195 49.3437 30.2597C49.3437 27.7 48.0639 25.3958 46.1437 24.0517L46.1437 23.9879L46.1437 20.9799C46.1437 18.4839 44.1599 16.5 41.6639 16.5C39.1679 16.5 37.184 18.4839 37.184 20.9799L37.184 24.0518C35.2639 25.4598 33.984 27.7 33.984 30.2598C33.984 32.8195 35.2639 35.1237 37.184 36.5317L37.184 36.7875L37.184 60.0195C37.184 62.5155 39.1679 64.4994 41.6639 64.4994C44.1599 64.4998 46.1437 62.5159 46.1437 60.0199L46.1437 60.0197ZM39.7437 20.9797C39.7437 19.8918 40.5759 19.0596 41.6639 19.0596C42.7518 19.0596 43.584 19.8917 43.584 20.9797L43.584 22.8355C42.9438 22.7074 42.3041 22.5797 41.6639 22.5797C41.0237 22.5797 40.384 22.6435 39.7437 22.8355L39.7437 20.9797ZM36.5437 30.2596C36.5437 27.4435 38.8477 25.1395 41.6639 25.1395C44.48 25.1395 46.784 27.4435 46.784 30.2596C46.784 33.0757 44.48 35.3797 41.6639 35.3797C38.8477 35.3797 36.5437 33.0757 36.5437 30.2596ZM39.7437 60.0196L39.7437 37.6836C40.3839 37.8117 41.0236 37.9394 41.6639 37.9394C42.304 37.9394 42.9437 37.8756 43.584 37.6836L43.584 60.0196C43.584 61.1076 42.7518 61.9397 41.6639 61.9397C40.5755 61.9397 39.7437 61.1076 39.7437 60.0196Z"
          fill="#0053F1"
        />
        <path
          d="M12.8644 60.0198L12.8644 44.7878C14.7846 43.3798 16.0645 41.1396 16.0645 38.5798C16.0645 36.0201 14.7846 33.716 12.8644 32.3718L12.8645 32.308L12.8645 20.9799C12.8645 18.4839 10.8806 16.5 8.38456 16.5C5.88856 16.5 3.90467 18.4839 3.90467 20.9799L3.90467 32.3719C1.98456 33.7799 0.704669 36.0201 0.704669 38.5799C0.704669 41.1397 1.98456 43.4438 3.90467 44.8518L3.90467 45.1076L3.90467 60.0196C3.90467 62.5156 5.88856 64.4995 8.38456 64.4995C10.8806 64.4999 12.8644 62.516 12.8644 60.02L12.8644 60.0198ZM6.46445 20.9798C6.46445 19.8919 7.29658 19.0597 8.38456 19.0597C9.47254 19.0597 10.3047 19.8919 10.3047 20.9798L10.3047 31.1557C9.66449 31.0276 9.02479 30.8999 8.38456 30.8999C7.74433 30.8999 7.10468 30.9638 6.46445 31.1557L6.46445 20.9798ZM3.26444 38.5798C3.26444 35.7637 5.56845 33.4597 8.38456 33.4597C11.2007 33.4597 13.5047 35.7637 13.5047 38.5798C13.5047 41.396 11.2007 43.7 8.38456 43.7C5.56844 43.7 3.26444 41.396 3.26444 38.5798ZM6.46445 60.0198L6.46445 46.0038C7.10463 46.132 7.74433 46.2596 8.38456 46.2596C9.02479 46.2596 9.66445 46.1958 10.3047 46.0038L10.3047 60.0198C10.3047 61.1078 9.47254 61.94 8.38456 61.94C7.29658 61.94 6.46445 61.1078 6.46445 60.0198Z"
          fill="#0053F1"
        />
        <path
          d="M24.9998 64.5C27.4958 64.5 29.4797 62.5161 29.4797 60.0201L29.4797 57.588C31.3998 56.18 32.6797 53.9397 32.6797 51.38C32.6797 48.8202 31.3998 46.5161 29.4797 45.172L29.4797 45.1081L29.4801 20.9801C29.4801 18.4841 27.4962 16.5002 25.0002 16.5002C22.5042 16.5002 20.5204 18.4841 20.5204 20.9801L20.5204 45.1721C18.6002 46.5801 17.3204 48.8204 17.3204 51.3801C17.3204 53.9399 18.6002 56.244 20.5204 57.652L20.5204 57.9078L20.5204 60.0198C20.5199 62.5163 22.5038 64.5002 24.9998 64.5002L24.9998 64.5ZM23.0801 20.98C23.0801 19.892 23.9123 19.0599 25.0003 19.0599C26.0882 19.0599 26.9204 19.892 26.9204 20.98L26.9204 43.956C26.2802 43.8278 25.6405 43.7002 25.0003 43.7002C24.3601 43.7002 23.7204 43.764 23.0801 43.956L23.0801 20.98ZM19.8801 51.38C19.8801 48.5639 22.1841 46.2599 25.0003 46.2599C27.8164 46.2599 30.1204 48.5639 30.1204 51.38C30.1204 54.1961 27.8164 56.5001 25.0003 56.5001C22.1841 56.5001 19.8801 54.1961 19.8801 51.38ZM23.0801 60.0202L23.0801 58.8041C23.7203 58.9322 24.36 59.0599 25.0003 59.0599C25.6404 59.0599 26.2801 58.9961 26.9204 58.8041L26.9204 60.0202C26.9204 61.1081 26.0882 61.9403 25.0003 61.9403C23.9119 61.9403 23.0801 61.1082 23.0801 60.0202Z"
          fill="#0053F1"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_376">
          <rect width="80" height="50" fill="white" transform="translate(50) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgEqualizerBullet;
