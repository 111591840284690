import * as React from "react";

export const SvgAmazonBullet: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6288 17.7368C11.5134 21.4399 5.08594 30.3836 5.08594 40.8285C5.08594 41.9915 5.16562 43.1359 5.31983 44.2565L7.69346 42.8861C7.63175 42.2085 7.60022 41.5222 7.60022 40.8285C7.60022 31.8032 12.9379 24.0245 20.6288 20.4771V17.7368Z"
        fill="#0053F1"
      />
      <path
        d="M30.0002 65.7428C24.2293 65.7428 18.9167 63.7807 14.693 60.4873L17.0645 59.1181C20.7182 61.707 25.1815 63.2285 30.0002 63.2285C34.8189 63.2285 39.2822 61.707 42.9359 59.1181L45.3074 60.4873C41.0837 63.7807 35.7711 65.7428 30.0002 65.7428Z"
        fill="#0053F1"
      />
      <path
        d="M54.6806 44.2565C54.8348 43.1359 54.9145 41.9915 54.9145 40.8285C54.9145 30.3836 48.4871 21.4399 39.3717 17.7368V20.4771C47.0625 24.0245 52.4002 31.8032 52.4002 40.8285C52.4002 41.5222 52.3687 42.2085 52.307 42.8861L54.6806 44.2565Z"
        fill="#0053F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3426 13.1715H25.6569V21.8572H34.3426V13.1715ZM23.1426 10.6572V24.3715H36.8569V10.6572H23.1426Z"
        fill="#0053F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.398 46.5533L3.87599 50.8961L8.21885 58.4182L15.7409 54.0753L11.398 46.5533ZM0.441406 49.9758L7.29855 61.8528L19.1755 54.9956L12.3183 43.1187L0.441406 49.9758Z"
        fill="#0053F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.122 50.8961L48.6 46.5532L44.2571 54.0753L51.7792 58.4182L56.122 50.8961ZM47.6797 43.1187L40.8225 54.9956L52.6995 61.8527L59.5566 49.9758L47.6797 43.1187Z"
        fill="#0053F1"
      />
    </svg>
  );
};

export default SvgAmazonBullet;
